import React from "react";
import NavigationBar from "./Components/Common/NavigationBar";
import FooterSection from "./Components/Common/FooterSection";
import { Routes, Route } from "react-router-dom";
import "./i18n";
import Statistics from "./Components/Pages/Statistics";
import AboutProject from "./Components/Pages/AboutProject";
import RouteNotFound from "./Components/Common/RouteNotFound";
import LandingPage from "./Components/Pages/LandingPage";
import YearsComparision from "./Components/Pages/YearsComparision";
import { StatisticsSectionProvider } from "./Context/StatisticsSectionContext";
import { YearsComparisionContextProvider } from "./Context/YearsComparisionContext";
import MainSection from "./Components/Common/MainSection";

function App() {
  return (
    <div className="App">
      <NavigationBar />

      <Routes>
        {/* Landing Page Route */}
        <Route path="/" element={<LandingPage />} />

        {/* Main Section Routes */}
        <Route element={<MainSection />}>
          <Route
            path="/statistiky"
            element={
              <StatisticsSectionProvider>
                <Statistics />
              </StatisticsSectionProvider>
            }
          />
          <Route path="/oprojekte" element={<AboutProject />} />
          <Route
            path="/rocneporovnanie"
            element={
              <YearsComparisionContextProvider>
                <YearsComparision />
              </YearsComparisionContextProvider>
            }
          />
        </Route>

        {/* Fallback Route */}
        <Route path="*" element={<RouteNotFound />} />
      </Routes>

      <FooterSection />
    </div>
  );
}

export default App;
