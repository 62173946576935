export const API_URL = `https://data.statistics.sk/api/v2/dataset/sp0207ts/`;
export const MAX_WEEK = 52;
export const MIN_WEEK = 1;
export const ROUND_DIGITS = 3;
export const INITIAL_FUEL_DISPLAY_COUNT = 7;
export const EMPTY_WEEK = "N/A";
export const EMPTY_VALUE = "-";
export const CURRENCY = "€";
export const MAX_WEEKS_TO_DISPLAY_PRICE = 5;
export const CURRENT_YEAR = new Date().getFullYear().toString();
export const CACHE_URL = "https://palivoslovensko.ddns.net:3000/fuels/";
