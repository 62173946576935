import React, { createContext, useContext, useState, useEffect } from "react";
import {
  MAX_WEEK,
  MIN_WEEK,
  CURRENT_YEAR,
  INITIAL_FUEL_DISPLAY_COUNT,
} from "../utils/constants.js";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  getCurrentWeekNumber,
  calculateWeeks,
  generateNumberString,
  processApiData,
  getDataWithParams,
} from "./helper-functions";

const StatisticsSectionContext = createContext();

export const useStatisticsSectionContext = () => {
  return useContext(StatisticsSectionContext);
};

export const StatisticsSectionProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = {
    year: searchParams.get("year")
      ? parseInt(searchParams.get("year"), 10)
      : CURRENT_YEAR,
    start: searchParams.get("start") || MIN_WEEK,
    end:
      searchParams.get("end") ||
      (CURRENT_YEAR === parseInt(searchParams.get("year"))
        ? getCurrentWeekNumber()
        : MAX_WEEK),
    fuels: searchParams.get("fuels")
      ? searchParams
          .get("fuels")
          .split(",")
          .map((fuel) => parseInt(fuel, 10))
          .filter((fuel) => !isNaN(fuel))
      : Array.from({ length: INITIAL_FUEL_DISPLAY_COUNT }, (_, index) => index),
    zoomed: searchParams.get("zoomed") || "none",
  };

  const [paramsState, setParamsState] = useState(params);
  const { i18n } = useTranslation();

  const [apiData, setApiData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateData = (newApiData, data, error) => {
    setError(error); // No need for change, if error is not passed it will be empty
    setApiData(newApiData);
    filterFuels(paramsState, newApiData);
  };

  const getDataFromCache = (savedDataKey, currentDate) => {
    const savedData = localStorage.getItem(savedDataKey);
    const savedDate = localStorage.getItem(`currentDate${i18n.language}`);
    const result = {
      apiData: [],
      data: [],
      error: null,
    };
    if (savedData && savedDate === JSON.stringify(currentDate)) {
      const parsedData = JSON.parse(savedData);
      if (parsedData?.length !== 0 || parsedData) {
        result.apiData = parsedData;
        result.data = parsedData;
        console.log("Data are from local storage");
        return result;
      }
    }
    result.error = new Error("No data");
    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = `${new Date().toLocaleDateString()}${i18n.language}`;
      const savedDataKey = `${paramsState.year}${i18n.language}`;
      const cachedData = getDataFromCache(savedDataKey, currentDate);

      if (!cachedData?.error && cachedData?.apiData?.length > 0) {
        updateData(cachedData.apiData, cachedData.data);
        setLoading(false);

        return;
      }
      const resultWeek = calculateWeeks(paramsState.year);
      const response = await getDataWithParams(
        generateNumberString(
          paramsState.year.toString() + resultWeek.toString(),
          `${paramsState.year}01`
        ),
        "all",
        i18n.language
      );
      const processedData = processApiData(response);
      if (!processedData) {
        updateData([], [], new Error("No data found."));
        setLoading(false);
        return;
      }
      updateData(processedData, processedData);
      localStorage.setItem(savedDataKey, JSON.stringify(processedData));
      localStorage.setItem(
        `currentDate${i18n.language}`,
        JSON.stringify(currentDate)
      );

      setLoading(false);
    };
    fetchData();
  }, [i18n.language, paramsState.year]);

  useEffect(() => {
    setSearchParamsFunction();
  }, [paramsState, setSearchParams]);

  const setSearchParamsFunction = () => {
    const fuelsString = paramsState.fuels
      ? paramsState.fuels.join(",")
      : [0, 1, 2, 3, 4, 5, 6];
    setSearchParams({
      year: paramsState.year.toString(),
      start: paramsState.start.toString(),
      end: paramsState.end.toString(),
      fuels: fuelsString,
      zoomed: paramsState.zoomed,
    });
  };

  const setZoomed = (newZoom) => {
    setParamsState((prevState) => ({ ...prevState, zoomed: newZoom }));
  };

  const filterFuelsByType = (fuelsToKeep, newData) => {
    return newData?.filter((_, index) => fuelsToKeep.includes(index));
  };
  const filterMeasuresByInterval = (start, end, newData) => {
    const filteredData = newData.map((fuel) => {
      const filteredMeasures = fuel?.measuresArray?.filter((weekObj) => {
        const weekNumber = parseInt(weekObj.week?.split(".")[0], 10); // Extract week number
        return weekNumber >= start && weekNumber <= end;
      });

      return {
        ...fuel,
        measuresArray: filteredMeasures,
      };
    });

    return filteredData;
  };

  const filterFuels = (newParamsState, newApiData) => {
    const filteredFuelsByType = filterFuelsByType(
      newParamsState.fuels,
      newApiData
    );
    const filteredFuelsByInterval = filterMeasuresByInterval(
      newParamsState.start,
      newParamsState.end,
      filteredFuelsByType
    );
    setData(filteredFuelsByInterval);
  };
  return (
    <StatisticsSectionContext.Provider
      value={{
        apiData,
        data,
        setData,
        error,
        setError,
        loading,
        paramsState,
        setParamsState,
        setSearchParams,
        filterFuels,

        setZoomed,
      }}
    >
      {children}
    </StatisticsSectionContext.Provider>
  );
};
