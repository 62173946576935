import React from "react";
import { Outlet } from "react-router-dom";

export default function MainSection() {
  return (
    <span id="main--section">
      <div className="grid min-h-[90vh] justify-items-center mx-auto md:px-[3vw]">
        <Outlet />
      </div>
    </span>
  );
}
// <PriceDevelopmentGraph data={data} />
