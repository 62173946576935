import {
  CACHE_URL,
  EMPTY_VALUE,
  MAX_WEEK,
  MIN_WEEK,
} from "../utils/constants.js";
import { API_URL } from "../utils/constants.js";
export const getDataWithParams = async (
  week = "all",
  fuel = "all",
  lang = "sk"
) => {
  let response;
  try {
    response = await fetch(`${CACHE_URL}${week}/${lang}`);
    if (response.status !== 200) throw new Error("Cache fail");
  } catch (error) {
    response = await fetch(`${API_URL}${week}/${fuel}?lang=${lang}&type=json`);
  }
  const result = await response.json();
  return result;
};

export function generateNumberString(start, end) {
  if (start === end) return start;

  let startNum = parseInt(start, 10);
  let endNum = parseInt(end, 10);

  if (startNum < endNum) {
    let tmp = startNum;
    startNum = endNum;
    endNum = tmp;
  }

  const numbers = [];

  for (let i = startNum; i >= endNum; i--) {
    numbers.push(i);
  }
  return numbers.join(",");
}

export function getCurrentWeekNumber() {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const daysDifference = Math.floor(
    (now - startOfYear) / (1000 * 60 * 60 * 24)
  );
  let result = Math.ceil((daysDifference + startOfYear.getDay() + 1) / 7);
  return result - 1 ?? MIN_WEEK;
}

export function processApiData(response) {
  if (response[0]?.name && response[0]?.measuresArray) return response;
  try {
    const fuelObjectsArray = [];
    const fuelsArray = Object.values(
      response.dimension.sp0207ts_ukaz.category.label
    );
    const numberOfFuels = fuelsArray.length;
    const numberOfMeasures = response.value.length;

    const weeksArray = Object.values(
      response.dimension.sp0207ts_tyz.category.label
    ).reverse();

    fuelsArray.forEach((fuel, index1) => {
      const fuelMeasures = [];
      let indexOfWeek = 0;
      for (
        let index2 = index1;
        index2 < numberOfMeasures;
        index2 = index2 + numberOfFuels
      ) {
        fuelMeasures.push({
          week: weeksArray[indexOfWeek],
          value: response.value[index2] ? response.value[index2] : EMPTY_VALUE,
        });
        indexOfWeek++;
      }
      fuelMeasures.reverse();
      fuelObjectsArray.push({
        name: fuel,
        measuresArray: fuelMeasures,
      });
    });

    return fuelObjectsArray.map((fuel) => ({
      ...fuel,
      measuresArray: fuel.measuresArray.filter(
        (measure) => measure.value !== "-"
      ),
    }));
  } catch (err) {
    return null;
  }
}

export function calculateWeeks(year) {
  const currentDate = new Date();
  if (year < currentDate.getFullYear()) {
    return MAX_WEEK;
  }

  const januaryFirst = new Date(currentDate.getFullYear(), 0, 1);
  const daysToNextMonday =
    januaryFirst.getDay() === 1 ? 0 : (7 - januaryFirst.getDay()) % 7;
  const nextMonday = new Date(
    currentDate.getFullYear(),
    0,
    januaryFirst.getDate() + daysToNextMonday
  );

  const weekNumber =
    currentDate < nextMonday
      ? MAX_WEEK
      : currentDate > nextMonday
      ? Math.ceil((currentDate - nextMonday) / (24 * 3600 * 1000) / 7)
      : MIN_WEEK;

  return weekNumber.toString().padStart(2, "0");
}
