import React, { createContext, useContext, useState, useEffect } from "react";
import { CURRENT_YEAR } from "../utils/constants.js";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  calculateWeeks,
  generateNumberString,
  processApiData,
  getDataWithParams,
} from "./helper-functions";

const YearsComparisionContext = createContext();

export const useYearsComparisionContext = () => {
  return useContext(YearsComparisionContext);
};

export const YearsComparisionContextProvider = ({ children }) => {
  const [apiData, setApiData] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const params = {
    firstYear: searchParams.get("firstYear")
      ? parseInt(searchParams.get("firstYear"), 10)
      : CURRENT_YEAR,
    secondYear: searchParams.get("secondYear")
      ? parseInt(searchParams.get("secondYear"))
      : CURRENT_YEAR - 1,
    fuel: searchParams.get("fuel") ? searchParams.get("fuel") : "0",
    zoomed: searchParams.get("zoomed") || "none",
  };

  const [paramsState, setParamsState] = useState(params);
  const { i18n } = useTranslation();

  const updateData = (newApiData, error) => {
    setError(error);
    setApiData(newApiData);

    filterFuels(paramsState, newApiData);
  };
  const filterFuelsByType = (fuelsToKeep, newData) => {
    return [newData[fuelsToKeep]];
  };

  const filterFuels = (newParamsState, newApiData) => {
    const filteredFirstFuelsByType = filterFuelsByType(
      newParamsState.fuel,
      newApiData.firstYearApiData
    );
    const filteredSecondFuelsByType = filterFuelsByType(
      newParamsState.fuel,
      newApiData.secondYearApiData
    );

    setData([...filteredFirstFuelsByType, ...filteredSecondFuelsByType]);
  };
  const getDataFromCache = (savedDataKey) => {
    const savedData = localStorage.getItem(savedDataKey);
    const result = {
      apiData: [],
      data: [],
      error: null,
    };
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      if (parsedData?.length !== 0 || parsedData) {
        result.apiData = parsedData;
        result.data = parsedData;
        console.log("Data are from local storage");
        return result;
      }
    }
    result.error = new Error("No data");
    return result;
  };
  useEffect(() => {
    const fetchYearData = async (year, savedDataKey, cachedData) => {
      if (cachedData.error || cachedData.apiData.length === 0) {
        const yearRequest = calculateWeeks(year);
        const response = await getDataWithParams(
          generateNumberString(
            year.toString() + yearRequest.toString(),
            `${year}01`
          ),
          "all",
          i18n.language
        );
        const processedData = processApiData(response);
        localStorage.setItem(savedDataKey, JSON.stringify(processedData));
        return processedData;
      }
      return cachedData.apiData;
    };

    const fetchData = async () => {
      const savedFirstDataKey = `${paramsState.firstYear}${i18n.language}`;
      const savedSecondDataKey = `${paramsState.secondYear}${i18n.language}`;

      const cachedFirstYearData = getDataFromCache(savedFirstDataKey);
      const cachedSecondYearData = getDataFromCache(savedSecondDataKey);

      try {
        const [firstYearApiData, secondYearApiData] = await Promise.all([
          fetchYearData(
            paramsState.firstYear,
            savedFirstDataKey,
            cachedFirstYearData
          ),
          fetchYearData(
            paramsState.secondYear,
            savedSecondDataKey,
            cachedSecondYearData
          ),
        ]);

        if (firstYearApiData.length > 0 || secondYearApiData.length > 0) {
          updateData({ firstYearApiData, secondYearApiData });
        } else {
          updateData({}, new Error("No data found."));
        }
      } catch (error) {
        updateData({}, new Error("Error fetching data."));
      }

      setLoading(false);
    };

    fetchData();
  }, [i18n.language, paramsState.firstYear, paramsState.secondYear]);

  const setZoomed = (newZoom) => {
    setParamsState((prevState) => ({ ...prevState, zoomed: newZoom }));
  };

  useEffect(() => {
    setSearchParamsFunction();
  }, [paramsState, setSearchParams]);

  const setSearchParamsFunction = () => {
    setSearchParams({
      firstYear: paramsState.firstYear.toString(),
      secondYear: paramsState.secondYear.toString(),
      fuel: paramsState.fuel.toString(),
      zoomed: paramsState.zoomed,
    });
  };
  return (
    <YearsComparisionContext.Provider
      value={{
        apiData,
        data,
        setData,
        error,
        setError,
        loading,
        paramsState,
        setParamsState,
        setSearchParams,
        filterFuels,
        setZoomed,
      }}
    >
      {children}
    </YearsComparisionContext.Provider>
  );
};
