import React from "react";
import {
  EMPTY_WEEK,
  EMPTY_VALUE,
  CURRENCY,
  ROUND_DIGITS,
} from "../../utils/constants.js";
import { useStatisticsSectionContext } from "../../Context/StatisticsSectionContext";
import Spinner from "./Spinner";
import { PiChartLineUpLight, PiChartLineDownLight } from "react-icons/pi";

import { GoDash } from "react-icons/go";

function isNumber(value) {
  if (Number.isNaN(value) || !Number.isFinite(value)) return false;
  return typeof value === "number";
}
function getFirstAndLastWeeks(measuresArray) {
  const parsedWeeks = measuresArray.filter(
    (measure) => measure.value !== EMPTY_VALUE
  ); // Filter out invalid measures
  if (parsedWeeks.length === 0) {
    return {
      firstWeek: { value: EMPTY_VALUE, week: EMPTY_WEEK },
      lastWeek: { value: EMPTY_VALUE, week: EMPTY_WEEK },
    };
  }

  const sortedWeeks = parsedWeeks.sort((a, b) => a.weekNumber - b.weekNumber); // Sort by ascending week number
  const firstWeek = sortedWeeks[0]; // First measure
  const lastWeek = sortedWeeks[sortedWeeks.length - 1]; // Last measure

  return { firstWeek, lastWeek };
}
const getStyle = (diff) => {
  const style = {};

  if (diff > 0) {
    style.bgColorClass = "border-2 border-solid border-red-700 bg-red-50";
    style.textColorClass = "text-rose-800";
    style.icon = <PiChartLineUpLight className="text-6xl" />;
    style.sign = "+";
  } else if (diff < 0) {
    style.bgColorClass = "border-2 border-solid border-green-700 bg-green-50";
    style.textColorClass = "text-green-800";
    style.icon = <PiChartLineDownLight className="text-6xl" />;
    style.sign = "";
  } else if (diff === EMPTY_VALUE || diff === 0) {
    style.bgColorClass = "border-2 border-solid border-blue-700 bg-blue-50";
    style.textColorClass = "text-blue-800";
    style.icon = <GoDash className="text-6xl" />;
    style.sign = "";
  }

  return style;
};

const getDate = (firstWeek, lastWeek) => {
  return `${firstWeek.week.split("-")[0].split("(")[1]}- ${lastWeek.week
    .split("-")[1]
    ?.replace(")", "")}`;
};

const getPercentageComponent = (
  styleObj,
  priceDifference,
  firstWeek,
  lastWeek,
  displayedCurrency
) => {
  if (priceDifference === 0 && priceDifference !== EMPTY_VALUE) return;

  const percentage = (lastWeek.value / firstWeek.value - 1) * 100;
  const percentageChange = isNumber(percentage)
    ? percentage.toFixed(ROUND_DIGITS) + "%"
    : "";
  return (
    <div className={`text-sm top-0 ${styleObj.textColorClass}`}>
      {`${styleObj.sign}${priceDifference}${displayedCurrency} (${percentageChange})`}
    </div>
  );
};

export default function CurrentWeek() {
  const { data, loading } = useStatisticsSectionContext();
  if (loading) return <Spinner />;
  let date;
  return (
    <div className="flex flex-row overflow-auto snap-x min-w-full py-2">
      {data?.map((fuel) => {
        const { lastWeek, firstWeek } = getFirstAndLastWeeks(
          fuel.measuresArray
        );
        date = date ?? getDate(firstWeek, lastWeek);
        let diff;
        if (firstWeek.value !== EMPTY_VALUE) {
          diff = lastWeek.value.toString() - firstWeek.value.toString();
        } else {
          diff = EMPTY_VALUE;
        }
        const styleObj = getStyle(diff);
        const priceDifference =
          diff !== EMPTY_VALUE ? diff.toFixed(ROUND_DIGITS) : 0;

        const displayedCurrency = diff !== EMPTY_VALUE ? CURRENCY : "";
        let percentageComponent = getPercentageComponent(
          styleObj,
          priceDifference,
          firstWeek,
          lastWeek,
          displayedCurrency
        );

        return (
          <div
            key={fuel.name}
            className={`grid ${styleObj.bgColorClass} min-w-fit gap-4 mx-[1.5px] grid grid-rows-[h-[15%],h-[20%],h-[40%] border-2 rounded p-2`}
            aria-label={`${firstWeek.week.split(".")[0]}-${
              lastWeek.week.split(".")[0]
            }`}
          >
            <div className="row-1 col-span-2 font-bold text-sm min-h-full">
              {date}
            </div>
            <div className="row-2 col-span-2 min-h-full text-xl font-semibold">
              {fuel.name.split("(")[0]}
            </div>
            <div className="row-3 row-span-2 col-span-1 min-h-full">
              <div className="flex flex-col ">
                <div className="text-2xl font-semibold">
                  {lastWeek.value}
                  {displayedCurrency}
                </div>
                {percentageComponent}
              </div>
            </div>
            <div
              className={`row-span-2 col-span-1 bottom-0 ${styleObj.textColorClass} min-h-full`}
            >
              {styleObj.icon}
            </div>
          </div>
        );
      })}
    </div>
  );
}
